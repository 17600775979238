<template>
    <svg class="svg" style="
        width: 100%;
        height: 100%;
        max-width: 30px;
        left: -3px;
        color: #343a40;
        position: relative;
    " width="42" height="18" viewBox="0 0 42 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.800781 0.9375H6.83594C8.13281 0.9375 9.23828 1.13281 10.1523 1.52344C11.0664 1.91406 11.7656 2.49219 12.25 3.25781C12.7422 4.01563 12.9883 4.95313 12.9883 6.07031C12.9883 6.92188 12.832 7.67188 12.5195 8.32031C12.207 8.96875 11.7656 9.51562 11.1953 9.96094C10.625 10.3984 9.94531 10.7383 9.15625 10.9805L8.26562 11.4141H2.83984L2.81641 9.08203H6.88281C7.58594 9.08203 8.17188 8.95703 8.64062 8.70703C9.10938 8.45703 9.46094 8.11719 9.69531 7.6875C9.9375 7.25 10.0586 6.75781 10.0586 6.21094C10.0586 5.61719 9.94141 5.10156 9.70703 4.66406C9.48047 4.21875 9.12891 3.87891 8.65234 3.64453C8.17578 3.40234 7.57031 3.28125 6.83594 3.28125H3.74219V18H0.800781V0.9375ZM10.5391 18L6.53125 10.3359L9.61328 10.3242L13.6797 17.8477V18H10.5391ZM20.875 18H17.2305L17.2539 15.668H20.875C21.9297 15.668 22.8125 15.4375 23.5234 14.9766C24.2422 14.5156 24.7812 13.8555 25.1406 12.9961C25.5078 12.1367 25.6914 11.1133 25.6914 9.92578V9C25.6914 8.07812 25.5859 7.26172 25.375 6.55078C25.1719 5.83984 24.8672 5.24219 24.4609 4.75781C24.0625 4.27344 23.5703 3.90625 22.9844 3.65625C22.4062 3.40625 21.7383 3.28125 20.9805 3.28125H17.1602V0.9375H20.9805C22.1133 0.9375 23.1484 1.12891 24.0859 1.51172C25.0234 1.88672 25.832 2.42969 26.5117 3.14062C27.1992 3.85156 27.7266 4.70312 28.0938 5.69531C28.4609 6.6875 28.6445 7.79688 28.6445 9.02344V9.92578C28.6445 11.1523 28.4609 12.2617 28.0938 13.2539C27.7266 14.2461 27.1992 15.0977 26.5117 15.8086C25.8242 16.5117 25.0039 17.0547 24.0508 17.4375C23.1055 17.8125 22.0469 18 20.875 18ZM18.8594 0.9375V18H15.918V0.9375H18.8594ZM33.4727 5.32031L35.8867 9.52734L38.3477 5.32031H41.4531L37.6094 11.543L41.6055 18H38.5L35.9219 13.6172L33.3438 18H30.2266L34.2109 11.543L30.3789 5.32031H33.4727Z"
            fill="white" />
    </svg>
</template>
<script>
export default {
    name: "icon-logo-rdx",
};
</script>
