<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.8157 17.0526H18.5262V6.57895H5.68408V10.5263" stroke="currentColor" stroke-width="1.5" />
        <line x1="1.73682" y1="17.05" x2="9.63155" y2="17.05" stroke="currentColor" stroke-width="1.5" />
        <line x1="5.75" y1="13" x2="5.75" y2="20.8947" stroke="currentColor" stroke-width="1.5" />
    </svg>
</template>

<script>
export default {
    name: "icon-dashboard-add-product",
};
</script>
