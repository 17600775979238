<template>
    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="7" width="20" height="10" rx="5" stroke="currentColor" stroke-width="1.5" />
        <circle cx="7" cy="12" r="3" fill="currentColor" />
    </svg>
</template>

<script>
export default {
    name: "icon-dashboard-toggle",
};
</script>
