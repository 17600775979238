<script setup>
import { ref, getCurrentInstance, onMounted, defineProps, defineEmits ,beforeMount} from "vue";
import { onBeforeMount } from 'vue';
import { useGlobalStore } from "@/stores/global.store";
import IconLogoCut from "@/components/icons/logo/IconLogoCut.vue";

const globalStore = useGlobalStore();

const props = defineProps({
  yesMethod: {
    type: Function,
    required: false,
  },
  noMethod: {
    type: Function,
    required: false,
  },
  label1: {
    type: String,
    required: false,
    default:
      "",
  },
  yesLabel: {
    type: String,
    required: false,
    default: "Entendido",
  },

});
onBeforeMount(() => {
  // Your code here
  
});

  

      


</script>

<template>
  <Dialog
    modal
    :closable="false"
    :visible="globalStore.alertvisible"
    :style="{ width: '28em', height: 'auto' }" 
    
  >
    <div class="centered">
      <p>
        {{ label1 }}
      </p>
    </div>

    <template #footer>
      <div class="centered mb-3">
        <Button class="yes-button" :label=yesLabel @click="$emit('enviar')" />
      </div>
      <!-- import svg -->
      <icon-logo-cut class="position-absolute logo-cut" />
    </template>
  </Dialog>
</template>
<style lang="scss" scoped>
.logo-cut {
  width: 10em;
  height: 10em;
  bottom: -0.5em;
  right: 18.8em;
  position: absolute;
  margin: 0.5em;
}
.centered {
  text-align: center;
  justify-content: center;
  p {
    font-family: "roboto";
    font-size: 1.2rem;
    font-weight: 500;
    color: black;
    width: 18em;

    margin: 0 auto;
  }
}

.p-button {
  font-family: "roboto";
  font-size: 0.75rem;
  font-weight: 500;
  width: 10em !important;
  margin: 0 auto;
  border-radius: 0.5em;
  border: none;
  padding: 0.75em;
  margin: 1em !important;
  &.yes-button {
    background: #ea3724 !important;
    color: white;
  }
  &.no-button {
    background: white !important;
    color: black;
    border: 1px solid black;
  }
}
</style>
