<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.591 5.21967C12.4503 5.07902 12.2596 5 12.0607 5C11.8617 5 11.671 5.07902 11.5303 5.21967L5.53033 11.2197L5 11.75L6.06066 12.8107L6.59099 12.2803L11.3107 7.56066V16.25V17H12.8107V16.25V7.56066L17.5303 12.2803L18.0607 12.8107L19.1213 11.75L18.591 11.2197L12.591 5.21967ZM7.31066 17.75V18.825H16.8107V17.75H17.8107V19.325V19.825H17.3107H6.81066H6.31066V19.325V17.75H7.31066Z"
      :fill="white ? '#fff' : '#8B8B8B'"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-dashboard-upload",
  props: {
    white: { default: false },
  },
};
</script>
