<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3.75" y="7.75" width="13.5" height="10.5" stroke="currentColor" stroke-width="1.5" />
        <path d="M6 5H20.5V17" stroke="currentColor" stroke-width="1.5" />
    </svg>
</template>

<script>
export default {
    name: "icon-dashboard-products",
};
</script>
