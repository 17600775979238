// import "./assets/js/nav-pills.js";
import "./assets/scss/rdx-dashboard.scss";


import IconLogoRDx from "@/components/icons/logo/IconLogoRDx.vue";

import IconUser from "@/components/icons/dashboard/IconUser.vue";
import IconSettings from "@/components/icons/dashboard/IconSettings.vue";
import IconApproved from "@/components/icons/dashboard/IconApproved.vue";

//social icons
import IconFacebook from "@/components/icons/social/IconFacebook.vue";
import IconInstagram from "@/components/icons/social/IconInstagram.vue";
import IconAirbnb from "@/components/icons/social/IconAirbnb.vue";


//dashboard
import IconLocation from "@/components/icons/dashboard/IconLocation.vue";
import IconContact from "@/components/icons/dashboard/IconContact.vue";
import IconNew from "@/components/icons/dashboard/IconNew.vue";
import IconShop from "@/components/icons/dashboard/IconShop.vue";
import IconToggle from "@/components/icons/dashboard/IconToggle.vue";
import IconHome from "@/components/icons/dashboard/IconHome.vue";
import IconAddProduct from "@/components/icons/dashboard/IconAddProduct.vue";
import IconProducts from "@/components/icons/dashboard/IconProducts.vue";
import IconTrainings from "@/components/icons/dashboard/IconTrainings.vue";
import Icon360 from "@/components/icons/dashboard/Icon360.vue";
import IconUpload from "@/components/icons/dashboard/IconUpload.vue";

import IconEdit from "@/components/icons/action/IconEdit.vue";


//method to set icons on vue components
export default {
  install() {
  },
  setIcons(vueApp) {
    vueApp.component(IconLogoRDx.name, IconLogoRDx);

    vueApp.component(IconUser.name, IconUser);
    vueApp.component(IconSettings.name, IconSettings);
    vueApp.component(IconApproved.name, IconApproved);

    vueApp.component(IconFacebook.name, IconFacebook);
    vueApp.component(IconInstagram.name, IconInstagram);
    vueApp.component(IconAirbnb.name, IconAirbnb);

    vueApp.component(IconLocation.name, IconLocation);
    vueApp.component(IconContact.name, IconContact);
    vueApp.component(IconNew.name, IconNew);
    vueApp.component(IconShop.name, IconShop);
    vueApp.component(IconToggle.name, IconToggle);
    vueApp.component(IconHome.name, IconHome);
    vueApp.component(IconAddProduct.name, IconAddProduct);
    vueApp.component(IconProducts.name, IconProducts);
    vueApp.component(IconTrainings.name, IconTrainings);
    vueApp.component(Icon360.name, Icon360);
    vueApp.component(IconUpload.name, IconUpload);

    vueApp.component(IconEdit.name, IconEdit);

  }
};

