import { gsap } from "gsap";

export default function DashboardEntrance() {
  let tl = gsap.timeline({ defaults: { duration: 1, ease: "power2.inOut" } });
  tl.pause();
  tl.from("#sidebar", { x: "-100%", delay: 0.3, duration: 0.5 });
  tl.pause();

  return tl
}
