<template>
<div :id="id" class="btn btn-primary cancel-button" :style="{ width: width }" >
    <slot></slot>

    <span v-if="show_plus" class="r-plus"> +</span>
  </div>
</template>

<style lang="scss" scoped>
.btn-primary {
  background-color: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color:white;
  box-shadow: none;
}

.cancel-button {
  background-color: transparent !important;
  color: #333333 !important;
  border: 2px solid #333333;
  padding-left: 24px;
  box-shadow: none !important;
  border-radius: 8px;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>

<script>
export default {
  name: "rdx-button-cancel",
  props: {
    width: { default: null },
  },
  data() {
    return {
      id: Math.random(),
      show_plus: false,
      isUploadDisabled: false,
    };
  },

  mounted() {
    var btn = document.getElementById(this.id);
    if (btn.className.search("r-plus") >= 0) {
      this.show_plus = true;
    }
    // Si no agregue ninguna clase, fuerzo a que sea r-bg-red
    if (btn.className == "btn") {
      btn.className = "btn btn-primarry";
    }

  },
  beforeUnmount() {

  },
  methods: {

  },
};
</script>