import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";




import axios from "axios";
import VueAxios from "vue-axios";

//import VueHead from 'vue-head';
import { createHead } from "@vueuse/head";

import App from "./App.vue";

import RDxDashboard from "./rdx-dashboard";

import { useSessionStore } from "./stores/session.store.js";
import { useGlobalStore } from "./stores/global.store.js";
import { useShowcaseStore } from "./stores/showcase.store.js";

import * as bootstrap from "bootstrap";

import { useApi } from "./helpers/api.js";
import { util } from "./helpers/util.js";
import { useGui } from "./helpers/gui.js";

import RDxButton from "@/components/RDxButton.vue";
import RDxButtonCancel from "@/components/RDxButtonCancel.vue";
import RDxInput from "@/components/RDxInput.vue";
import RDxCheckbox from "@/components/RDxCheckbox.vue";
import RDxLoader from "@/components/RDxLoader.vue";
import RDxConfirm from "@/components/RDxConfirm.vue";
import RDxImageItem from "@/components/RDxImageItem.vue";


import IconLogoName from "@/components/icons/IconLogoName.vue";
import IconLogoIsotype from "@/components/icons/logo/IconLogoIsotype.vue";
import IconLogoFull from "@/components/icons/logo/IconLogoFull.vue";

/* PRIMEVUE IMPORTS */
import PrimeVue from "primevue/config";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Skeleton from "primevue/skeleton";
import Tooltip from "primevue/tooltip";

/* etc */
import ScrollReveal from "scrollreveal";
import aframe from "aframe";
import cors from "./cors";
import { vScrollReveal } from "vue-scroll-reveal";
import VueScrollReveal from "vue-scroll-reveal";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Flip from "gsap/Flip";
import Draggable from "gsap/Draggable";
import CSSRulePlugin from "gsap/CSSRulePlugin";
import VueProgressBar from 'vue-progressbar'
import { handleTokenError } from './utils/handleTokenError'

gsap.registerPlugin(ScrollTrigger, Flip, Draggable, CSSRulePlugin);

//theme
import "primevue/resources/themes/lara-light-indigo/theme.css";

//core
import "primevue/resources/primevue.min.css";

//icons
import "primeicons/primeicons.css";

//import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const head = createHead();
import router from "./router";


const vueApp = createApp(App)
  .use(router)
  .use(VueAxios, axios)
  .use(head)
  .use(pinia);

vueApp.use(RDxDashboard);
vueApp.use(PrimeVue);
vueApp.use(VueScrollReveal);


// vueApp.component(IconUser.name, IconUser);
// vueApp.component(IconSettings.name, IconSettings);
RDxDashboard.setIcons(vueApp);
vueApp.component(IconLogoName.name, IconLogoName);
vueApp.component(IconLogoIsotype.name, IconLogoIsotype);
vueApp.component(IconLogoFull.name, IconLogoFull);

vueApp.component("Button", Button);
vueApp.component("Dialog", Dialog);
vueApp.component("InputText", InputText);
vueApp.component("Skeleton", Skeleton);

vueApp.component(RDxButtonCancel.name, RDxButtonCancel);
vueApp.component(RDxButton.name, RDxButton);
vueApp.component(RDxInput.name, RDxInput);
vueApp.component(RDxCheckbox.name, RDxCheckbox);
vueApp.component(RDxLoader.name, RDxLoader);
vueApp.component(RDxConfirm.name, RDxConfirm);
vueApp.component(RDxImageItem.name, RDxImageItem);

vueApp.config.globalProperties.$store = {};

vueApp.config.globalProperties.$store.global = useGlobalStore();
vueApp.config.globalProperties.$store.showcase = useShowcaseStore();
vueApp.config.globalProperties.$store.session = useSessionStore();



vueApp.config.globalProperties.$api = useApi(vueApp,handleTokenError);
vueApp.config.globalProperties.$gui = useGui(vueApp);
vueApp.config.globalProperties.$u = util;
vueApp.config.globalProperties.$handleTokenError = handleTokenError;

window.bootstrap = bootstrap;

vueApp.directive("tooltip", Tooltip);


import vue3GoogleLogin from 'vue3-google-login'



// vueApp.use(vue3GoogleLogin, {
//   clientId: process.env.VUE_APP_GOOGLE_AUTH2_CLIENT_ID,
// })


//init gauth


/* cors */

vueApp.mount("#app");


//google login
