<template>
  <div class="form-floating mb-3">
    <input
      v-bind:class="'form-control'"
      class="fade-in"
      :style="{
        animationDuration: animationDuration,
      }"
      :type="type"
      :id="id"
      :name="name"
      :placeholder="placeholder"
      :isRequired="isRequired"
      v-model="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      v-bind="$attrs"
    />
    <label :for="id">{{ label }}</label>
  </div>
</template>

<style scoped lang="scss">
.form-floating {
  input {
    border-width: 0 0 0.1rem 0;
    border-radius: 0;
    &:focus {
      box-shadow: none;
    }
  }
}
</style>

<script>
export default {
  name: "rdx-input",
  computed: {
    animationDuration() {
      return `${0.75*Math.random()}s`;
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: String,
    label: String,
    placeholder: String,
    type: String,
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modelValue: null,
    };
  },
};
</script>
<style lang="scss" scoped>
input::placeholder {
  color: transparent !important;
}
</style>
