<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3.75" y="13.75" width="6.5" height="6.5" stroke="currentColor" stroke-width="1.5" />
        <rect x="3.75" y="3.75" width="6.5" height="6.5" stroke="currentColor" stroke-width="1.5" />
        <rect x="13.75" y="13.75" width="6.5" height="6.5" stroke="currentColor" stroke-width="1.5" />
        <rect x="13.75" y="3.75" width="6.5" height="6.5" stroke="currentColor" stroke-width="1.5" />
    </svg>
</template>

<script>
export default {
    name: "icon-dashboard-home",
};
</script>
