<template>
  <svg
    width="137"
    height="472"
    viewBox="0 0 137 472"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_117_5190)">
      <path d="M86.295 94.5833H83.2109V101.858H86.295V94.5833Z" fill="black" />
      <path
        d="M36.3648 87.3088H33.2808V94.5832H36.3648V87.3088Z"
        fill="black"
      />
      <path
        d="M102.935 87.3088H99.8511V94.5832H102.935V87.3088Z"
        fill="black"
      />
      <path
        d="M41.9146 87.3088H38.8306V94.5832H41.9146V87.3088Z"
        fill="black"
      />
      <path
        d="M80.7451 94.5833H77.6611V101.858H80.7451V94.5833Z"
        fill="black"
      />
      <path
        d="M47.4644 87.3088H44.3804V94.5832H47.4644V87.3088Z"
        fill="black"
      />
      <path
        d="M53.0054 94.5833H49.9214V101.858H53.0054V94.5833Z"
        fill="black"
      />
      <path
        d="M97.3858 87.3088H94.3018V94.5832H97.3858V87.3088Z"
        fill="black"
      />
      <path
        d="M58.5552 94.5833H55.4712V101.858H58.5552V94.5833Z"
        fill="black"
      />
      <path d="M91.836 87.3088H88.752V94.5832H91.836V87.3088Z" fill="black" />
      <path d="M3.08402 65.4775H0V72.7519H3.08402V65.4775Z" fill="black" />
      <path d="M8.63382 72.7603H5.5498V80.0346H8.63382V72.7603Z" fill="black" />
      <path
        d="M30.8238 72.7603H27.7397V80.0346H30.8238V72.7603Z"
        fill="black"
      />
      <path d="M53.0054 80.0347H49.9214V87.309H53.0054V80.0347Z" fill="black" />
      <path
        d="M136.216 65.4775H133.132V72.7519H136.216V65.4775Z"
        fill="black"
      />
      <path
        d="M122.042 80.0346V87.3089H125.126V80.0346V72.7603H122.042V80.0346Z"
        fill="black"
      />
      <path
        d="M110.942 80.0346V87.3089H114.026V80.0346V72.7603H110.942V80.0346Z"
        fill="black"
      />
      <path
        d="M130.667 72.7603H127.583V80.0346H130.667V72.7603Z"
        fill="black"
      />
      <path d="M119.576 80.0347H116.492V87.309H119.576V80.0347Z" fill="black" />
      <path
        d="M108.477 72.7603H105.393V80.0346H108.477V72.7603Z"
        fill="black"
      />
      <path
        d="M11.0908 80.0346V87.3089H14.1831V80.0346V72.7603H11.0908V80.0346Z"
        fill="black"
      />
      <path d="M19.7246 80.0347H16.6406V87.309H19.7246V80.0347Z" fill="black" />
      <path
        d="M22.1899 80.0346V87.3089H25.274V80.0346V72.7603H22.1899V80.0346Z"
        fill="black"
      />
      <path
        d="M36.3648 72.7603H33.2808V80.0346H36.3648V72.7603Z"
        fill="black"
      />
      <path
        d="M102.935 72.7603H99.8511V80.0346H102.935V72.7603Z"
        fill="black"
      />
      <path
        d="M97.3858 72.7603H94.3018V80.0346H97.3858V72.7603Z"
        fill="black"
      />
      <path
        d="M41.9146 72.7603H38.8306V80.0346H41.9146V72.7603Z"
        fill="black"
      />
      <path d="M91.836 65.4775H88.752V72.7519H91.836V65.4775Z" fill="black" />
      <path d="M86.295 80.0347H83.2109V87.309H86.295V80.0347Z" fill="black" />
      <path
        d="M72.1113 87.309V94.5833H75.1953V87.309V80.0347H72.1113V87.309Z"
        fill="black"
      />
      <path d="M58.5552 80.0347H55.4712V87.309H58.5552V80.0347Z" fill="black" />
      <path d="M80.7451 80.0347H77.6611V87.309H80.7451V80.0347Z" fill="black" />
      <path
        d="M61.0205 87.309V94.5833H64.1045V87.309V80.0347H61.0205V87.309Z"
        fill="black"
      />
      <path
        d="M102.935 58.2031H99.8511V65.4775H102.935V58.2031Z"
        fill="black"
      />
      <path
        d="M11.0908 50.9289V58.2032H14.1831V50.9289V43.6545H11.0908V50.9289Z"
        fill="black"
      />
      <path d="M19.7246 50.9287H16.6406V58.203H19.7246V50.9287Z" fill="black" />
      <path d="M25.274 58.2031H22.1899V65.4775H25.274V58.2031Z" fill="black" />
      <path
        d="M30.8238 58.2031H27.7397V65.4775H30.8238V58.2031Z"
        fill="black"
      />
      <path d="M86.295 50.9287H83.2109V58.203H86.295V50.9287Z" fill="black" />
      <path
        d="M122.042 50.9289V58.2032H125.126V50.9289V43.6545H122.042V50.9289Z"
        fill="black"
      />
      <path d="M119.576 50.9287H116.492V58.203H119.576V50.9287Z" fill="black" />
      <path
        d="M114.026 58.2031H110.942V65.4775H114.026V58.2031Z"
        fill="black"
      />
      <path
        d="M108.477 58.2031H105.393V65.4775H108.477V58.2031Z"
        fill="black"
      />
      <path
        d="M47.4644 65.4775H44.3804V72.7519H47.4644V65.4775Z"
        fill="black"
      />
      <path d="M41.9146 50.9287H38.8306V58.203H41.9146V50.9287Z" fill="black" />
      <path d="M97.3858 50.9287H94.3018V58.203H97.3858V50.9287Z" fill="black" />
      <path d="M91.836 50.9287H88.752V58.203H91.836V50.9287Z" fill="black" />
      <path d="M47.4644 50.9287H44.3804V58.203H47.4644V50.9287Z" fill="black" />
      <path
        d="M53.0054 65.4775H49.9214V72.7519H53.0054V65.4775Z"
        fill="black"
      />
      <path d="M86.295 65.4775H83.2109V72.7519H86.295V65.4775Z" fill="black" />
      <path
        d="M72.1113 65.4775V72.7603H75.1953V65.4775V58.2031H72.1113V65.4775Z"
        fill="black"
      />
      <path
        d="M61.0205 65.4775V72.7603H64.1045V65.4775V58.2031H61.0205V65.4775Z"
        fill="black"
      />
      <path d="M25.274 29.1057H22.1899V36.38H25.274V29.1057Z" fill="black" />
      <path d="M53.0054 50.9287H49.9214V58.203H53.0054V50.9287Z" fill="black" />
      <path
        d="M61.0205 43.6545V50.9288H64.1045V43.6545V36.3801H61.0205V43.6545Z"
        fill="black"
      />
      <path
        d="M33.2808 36.38V43.6544H36.3648V36.38V29.1057H33.2808V36.38Z"
        fill="black"
      />
      <path d="M114.026 29.1057H110.942V36.38H114.026V29.1057Z" fill="black" />
      <path
        d="M105.393 29.1059V36.3802H108.485V29.1059V21.8315H105.393V29.1059Z"
        fill="black"
      />
      <path
        d="M99.8511 36.38V43.6544H102.935V36.38V29.1057H99.8511V36.38Z"
        fill="black"
      />
      <path
        d="M41.9146 36.3801H38.8306V43.6545H41.9146V36.3801Z"
        fill="black"
      />
      <path
        d="M72.1113 43.6545V50.9288H75.1953V43.6545V36.3801H72.1113V43.6545Z"
        fill="black"
      />
      <path
        d="M47.4644 36.3801H44.3804V43.6545H47.4644V36.3801Z"
        fill="black"
      />
      <path
        d="M49.9214 36.38V43.6544H53.0054V36.38V29.1057H49.9214V36.38Z"
        fill="black"
      />
      <path
        d="M55.4712 36.38V43.6544V50.9287V58.203V65.4774V72.7602H58.5552V65.4774V58.203V50.9287V43.6544V36.38V29.1057H55.4712V36.38Z"
        fill="black"
      />
      <path
        d="M97.3858 36.3801H94.3018V43.6545H97.3858V36.3801Z"
        fill="black"
      />
      <path d="M91.836 36.3801H88.752V43.6545H91.836V36.3801Z" fill="black" />
      <path
        d="M83.2109 36.38V43.6544H86.295V36.38V29.1057H83.2109V36.38Z"
        fill="black"
      />
      <path
        d="M77.6611 36.38V43.6544V50.9287V58.203V65.4774V72.7602H80.7451V65.4774V58.203V50.9287V43.6544V36.38V29.1057H77.6611V36.38Z"
        fill="black"
      />
      <path
        d="M27.7397 29.1059V36.3802H30.8238V29.1059V21.8315H27.7397V29.1059Z"
        fill="black"
      />
      <path
        d="M61.0205 21.8314V29.1057H64.1045V21.8314V14.5486H61.0205V21.8314Z"
        fill="black"
      />
      <path
        d="M97.3858 14.5486H94.3018V21.8229H97.3858V14.5486Z"
        fill="black"
      />
      <path
        d="M80.7451 14.5486H77.6611V21.8229H80.7451V14.5486Z"
        fill="black"
      />
      <path
        d="M47.4644 21.8315H44.3804V29.1059H47.4644V21.8315Z"
        fill="black"
      />
      <path d="M91.836 21.8315H88.752V29.1059H91.836V21.8315Z" fill="black" />
      <path
        d="M72.1113 21.8314V29.1057H75.1953V21.8314V14.5486H72.1113V21.8314Z"
        fill="black"
      />
      <path d="M86.295 7.27441H83.2109V14.5487H86.295V7.27441Z" fill="black" />
      <path
        d="M102.935 7.27441H99.8511V14.5487H102.935V7.27441Z"
        fill="black"
      />
      <path
        d="M58.5552 14.5486H55.4712V21.8229H58.5552V14.5486Z"
        fill="black"
      />
      <path
        d="M41.9146 14.5486H38.8306V21.8229H41.9146V14.5486Z"
        fill="black"
      />
      <path
        d="M36.3648 7.27441H33.2808V14.5487H36.3648V7.27441Z"
        fill="black"
      />
      <path
        d="M66.1621 17.9804V28.9735V39.9539V50.9343V61.9146V72.895V83.8754V94.8557V105.849V116.829V127.81V138.79V149.77V160.751V171.731V182.724V193.705V204.685V215.665V226.646V237.626V248.606V473H70.054V248.606V237.626V226.646V215.665V204.685V193.705V182.724V171.731V160.751V149.77V138.79V127.81V116.829V105.849V94.8557V83.8754V72.895V61.9146V50.9343V39.9539V28.9735V17.9804V7H66.1621V17.9804Z"
        fill="black"
      />
      <path
        d="M53.0054 7.27441H49.9214V14.5487H53.0054V7.27441Z"
        fill="black"
      />
      <path d="M91.836 0H88.752V7.27433H91.836V0Z" fill="black" />
      <path d="M64.1045 0H61.0205V7.27433H64.1045V0Z" fill="black" />
      <path d="M75.1953 0H72.1113V7.27433H75.1953V0Z" fill="black" />
      <path d="M47.4644 0H44.3804V7.27433H47.4644V0Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_117_5190">
        <rect width="136.216" height="472" fill="black" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "icon-logo-isotype-long",
  props: {
    fill: {
      type: String,
      default: "#000",
    },
  },
};
</script>
