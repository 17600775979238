<template>
  <div></div>
  <!-- <div
    style="
      position: fixed;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      /* background: rgb(249, 249, 249);
      opacity: 0.8; */
    "
  >
   
  </div> -->
</template>

<style lang="scss" scoped>
// @import "@/styles/rdx-dashboard.scss";
</style>

<script>
export default {
  name: "rdx-loader",
  props: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.araucania-loader {
  height: max(20vh, 15vw);
  /* red tint */
  animation-duration: 0.1s; /* Change the duration to make the GIF go faster */

  /*red*/
}

.tinted {
  filter: invert(22%) sepia(50%) saturate(12514%) hue-rotate(354deg)
    brightness(104%) contrast(84%);
}
</style>
