<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 18C9 16.4087 9.63214 14.8826 10.7574 13.7574C11.8826 12.6321 13.4087 12 15 12C13.4087 12 11.8826 11.3679 10.7574 10.2426C9.63214 9.11742 9 7.5913 9 6C9 7.5913 8.36786 9.11742 7.24264 10.2426C6.11742 11.3679 4.5913 12 3 12C4.5913 12 6.11742 12.6321 7.24264 13.7574C8.36786 14.8826 9 16.4087 9 18Z"
            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M17.4142 18.5858C17.0391 18.2107 16.5304 18 16 18C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16C18 16.5304 18.2107 17.0391 18.5858 17.4142C18.9609 17.7893 19.4696 18 20 18C19.4696 18 18.9609 18.2107 18.5858 18.5858C18.2107 18.9609 18 19.4696 18 20C18 19.4696 17.7893 18.9609 17.4142 18.5858Z"
            fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M16.4142 7.58579C16.0391 7.21071 15.5304 7 15 7C15.5304 7 16.0391 6.78929 16.4142 6.41421C16.7893 6.03914 17 5.53043 17 5C17 5.53043 17.2107 6.03914 17.5858 6.41421C17.9609 6.78929 18.4696 7 19 7C18.4696 7 17.9609 7.21071 17.5858 7.58579C17.2107 7.96086 17 8.46957 17 9C17 8.46957 16.7893 7.96086 16.4142 7.58579Z"
            fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>

<script>
export default {
    name: "icon-dashboard-new",
};
</script>
