<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 22L11.75 19H11.5C9.13333 19 7.125 18.175 5.475 16.525C3.825 14.875 3 12.8667 3 10.5C3 8.13333 3.825 6.125 5.475 4.475C7.125 2.825 9.13333 2 11.5 2C12.6833 2 13.7873 2.22067 14.812 2.662C15.8373 3.104 16.7377 3.71233 17.513 4.487C18.2877 5.26233 18.8957 6.16233 19.337 7.187C19.779 8.21233 20 9.31667 20 10.5C20 11.75 19.796 12.95 19.388 14.1C18.9793 15.25 18.421 16.3167 17.713 17.3C17.0043 18.2833 16.1627 19.175 15.188 19.975C14.2127 20.775 13.15 21.45 12 22Z"
            stroke="currentColor" stroke-width="1.5" />
        <path
            d="M10.602 13.1702V13.1128C10.6088 12.5041 10.6769 12.0198 10.8061 11.6597C10.9354 11.2996 11.119 11.008 11.3571 10.7849C11.5952 10.5618 11.881 10.3563 12.2143 10.1683C12.415 10.0535 12.5952 9.9181 12.7551 9.76195C12.915 9.60261 13.0408 9.41938 13.1327 9.21224C13.2279 9.0051 13.2755 8.77565 13.2755 8.5239C13.2755 8.2116 13.1973 7.94073 13.0408 7.71128C12.8844 7.48184 12.6752 7.30497 12.4133 7.18069C12.1514 7.0564 11.8605 6.99426 11.5408 6.99426C11.2619 6.99426 10.9932 7.04844 10.7347 7.15679C10.4762 7.26514 10.2602 7.43563 10.0867 7.66826C9.91327 7.90089 9.81293 8.20523 9.78571 8.58126H8.5C8.52721 8.03952 8.67687 7.57584 8.94898 7.19025C9.22449 6.80465 9.58673 6.50988 10.0357 6.30593C10.4881 6.10198 10.9898 6 11.5408 6C12.1395 6 12.6599 6.11154 13.102 6.33461C13.5476 6.55768 13.8912 6.86361 14.1327 7.25239C14.3776 7.64117 14.5 8.08413 14.5 8.58126C14.5 8.9318 14.4422 9.24888 14.3265 9.53251C14.2143 9.81612 14.051 10.0695 13.8367 10.2925C13.6259 10.5156 13.3707 10.7132 13.0714 10.8853C12.7721 11.0605 12.5323 11.2454 12.352 11.4398C12.1718 11.631 12.0408 11.8588 11.9592 12.1233C11.8776 12.3878 11.8333 12.7177 11.8265 13.1128V13.1702H10.602ZM11.2551 16C11.0034 16 10.7874 15.9156 10.6071 15.7467C10.4269 15.5778 10.3367 15.3754 10.3367 15.1396C10.3367 14.9038 10.4269 14.7014 10.6071 14.5325C10.7874 14.3636 11.0034 14.2792 11.2551 14.2792C11.5068 14.2792 11.7228 14.3636 11.9031 14.5325C12.0833 14.7014 12.1735 14.9038 12.1735 15.1396C12.1735 15.2957 12.131 15.4391 12.0459 15.5698C11.9643 15.7004 11.8537 15.8056 11.7143 15.8853C11.5782 15.9618 11.4252 16 11.2551 16Z"
            fill="currentColor" />
    </svg>
</template>

<script>
export default {
    name: "icon-dashboard-contact",
};
</script>
