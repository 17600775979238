export function handleTokenError(error) {
  if (
    error.response.data.error === "token expirado o inválido" ||
    error.response.data.detail ===
      "Authentication credentials were not provided."
  ) {
    if (
      localStorage.getItem("session") === null ||
      localStorage.getItem("session") === undefined
    ) {
      return;
    }
    let jsonsession = JSON.parse(localStorage.getItem("session"));
    if (jsonsession.token === null || jsonsession.token === undefined) {
      return;
    }
    // Crear un div para el mensaje de redirección

    const backgroundContainer = document.createElement("div");
    backgroundContainer.style.position = "fixed";
    backgroundContainer.style.top = "0";
    backgroundContainer.style.left = "0";
    backgroundContainer.style.width = "100%";
    backgroundContainer.style.height = "100%";
    // Aplicar desenfoque al fondo
    backgroundContainer.style.backgroundColor = "rgba(0, 0, 0, 0.3)";

    backgroundContainer.addEventListener("click", () => {
      const loginUrl = "/session/login"; // Cambia esto por la URL de tu página de inicio de sesión
      window.location.href = loginUrl;
    });

    const alertContainer = document.createElement("div");
    alertContainer.style.position = "fixed";
    alertContainer.style.top = "50%";
    alertContainer.style.left = "50%";
    alertContainer.style.transform = "translate(-50%, -50%)";
    alertContainer.style.backgroundColor = "rgba(255, 249, 244)";
    alertContainer.style.borderRadius = "0.5em";
    alertContainer.style.padding = "1em";
    alertContainer.style.boxShadow = "0 0 1em rgba(0, 0, 0, 0.2)";
    alertContainer.style.width = "40em";
    alertContainer.style.height = "20em";
    alertContainer.style.display = "flex";
    alertContainer.style.flexDirection = "column";
    alertContainer.style.justifyContent = "center";
    alertContainer.style.alignItems = "center";

    // Crear un div para el texto arriba
    const upperText = document.createElement("div");
    upperText.textContent = "Su sesión ha terminado";
    upperText.style.fontFamily = "roboto";
    upperText.style.fontSize = "1.2rem";
    upperText.style.fontWeight = "500";
    upperText.style.color = "black";

    // Crear un div para el texto abajo
    const lowerText = document.createElement("div");
    lowerText.textContent = "Regresando al inicio de sesión.";
    lowerText.style.fontFamily = "roboto";
    lowerText.style.fontSize = "1.2rem";
    lowerText.style.fontWeight = "500";
    lowerText.style.color = "black";

    // Agregar los elementos al contenedor
    alertContainer.appendChild(upperText);
    alertContainer.appendChild(lowerText);

    // Agregar el contenedor del mensaje al cuerpo del documento
    const svgContainer = document.createElement("div");
    svgContainer.innerHTML = `
        <svg
    width="200"
    height="300"
    
    viewBox="0 0 205 277"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M86.5946 259.446H78.2168V278.567H86.5946V259.446Z"
      fill="#131313"
    />
    <path
      d="M71.5184 259.446H63.1406V278.567H71.5184V259.446Z"
      fill="#131313"
    />
    <path d="M56.4598 259.446H48.082V278.567H56.4598V259.446Z" fill="#131313" />
    <path d="M177 202.084H168.622V221.205H177V202.084Z" fill="#131313" />
    <path
      d="M138.486 240.326V259.446H146.864V240.326V221.205H138.486V240.326Z"
      fill="#131313"
    />
    <path
      d="M108.353 240.326V259.446H116.73V240.326V221.205H108.353V240.326Z"
      fill="#131313"
    />
    <path
      d="M161.923 221.205H153.545V240.326H161.923V221.205Z"
      fill="#131313"
    />
    <path
      d="M131.789 240.326H123.411V259.446H131.789V240.326Z"
      fill="#131313"
    />
    <path
      d="M101.653 221.205H93.2754V240.326H101.653V221.205Z"
      fill="#131313"
    />
    <path
      d="M86.5946 221.205H78.2168V240.326H86.5946V221.205Z"
      fill="#131313"
    />
    <path
      d="M71.5184 221.205H63.1406V240.326H71.5184V221.205Z"
      fill="#131313"
    />
    <path d="M56.4598 202.084H48.082V221.205H56.4598V202.084Z" fill="#131313" />
    <path
      d="M41.3837 240.326H33.0059V259.446H41.3837V240.326Z"
      fill="#131313"
    />
    <path
      d="M2.87012 259.446V278.567H11.2658V259.446V240.326H2.87012V259.446Z"
      fill="#131313"
    />
    <path
      d="M26.3241 240.326H17.9463V259.446H26.3241V240.326Z"
      fill="#131313"
    />
    <path
      d="M86.5946 182.964H78.2168V202.084H86.5946V182.964Z"
      fill="#131313"
    />
    <path
      d="M41.3837 163.843H33.0059V182.964H41.3837V163.843Z"
      fill="#131313"
    />
    <path
      d="M138.486 163.843V182.964H146.864V163.843V144.723H138.486V163.843Z"
      fill="#131313"
    />
    <path
      d="M131.789 163.843H123.411V182.964H131.789V163.843Z"
      fill="#131313"
    />
    <path d="M116.73 182.964H108.353V202.084H116.73V182.964Z" fill="#131313" />
    <path
      d="M101.653 182.964H93.2754V202.084H101.653V182.964Z"
      fill="#131313"
    />
    <path
      d="M71.5184 163.843H63.1406V182.964H71.5184V163.843Z"
      fill="#131313"
    />
    <path d="M56.4598 163.843H48.082V182.964H56.4598V163.843Z" fill="#131313" />
    <path
      d="M41.3837 202.084H33.0059V221.205H41.3837V202.084Z"
      fill="#131313"
    />
    <path
      d="M2.87012 202.084V221.205H11.2658V202.084V182.964H2.87012V202.084Z"
      fill="#131313"
    />
    <path d="M116.73 106.482H108.353V125.603H116.73V106.482Z" fill="#131313" />
    <path
      d="M93.2754 106.482V125.603H101.671V106.482V87.3618H93.2754V106.482Z"
      fill="#131313"
    />
    <path
      d="M78.2168 125.603V144.723H86.5946V125.603V106.482H78.2168V125.603Z"
      fill="#131313"
    />
    <path
      d="M2.87012 144.722V163.843H11.2658V144.722V125.602H2.87012V144.722Z"
      fill="#131313"
    />
    <path
      d="M71.5184 125.602H63.1406V144.722H71.5184V125.602Z"
      fill="#131313"
    />
    <path d="M56.4598 125.602H48.082V144.722H56.4598V125.602Z" fill="#131313" />
    <path
      d="M33.0059 125.603V144.723H41.4016V125.603V106.482H33.0059V125.603Z"
      fill="#131313"
    />
    <path
      d="M17.9463 125.603V144.723V163.844V182.964V202.085V221.205H26.3241V202.085V182.964V163.844V144.723V125.603V106.482H17.9463V125.603Z"
      fill="#131313"
    />
    <path
      d="M71.5184 68.2412H63.1406V87.3617H71.5184V68.2412Z"
      fill="#131313"
    />
    <path
      d="M26.3241 68.2412H17.9463V87.3617H26.3241V68.2412Z"
      fill="#131313"
    />
    <path d="M56.4598 87.3618H48.082V106.482H56.4598V87.3618Z" fill="#131313" />
    <path
      d="M2.87012 87.3617V106.482H11.2658V87.3617V68.2412H2.87012V87.3617Z"
      fill="#131313"
    />
    <path
      d="M41.3837 49.1206H33.0059V68.2411H41.3837V49.1206Z"
      fill="#131313"
    />
    <path
      d="M86.5946 49.1206H78.2168V68.2411H86.5946V49.1206Z"
      fill="#131313"
    />
    <path d="M56.4598 30H48.082V49.1205H56.4598V30Z" fill="#131313" />
    <path d="M11.2479 30H2.87012V49.1205H11.2479V30Z" fill="#131313" />
  </svg>
    `;
    svgContainer.style.position = "fixed";
    svgContainer.style.bottom = "0em";
    svgContainer.style.left = "-0.15em";
    // Agregar el elemento img al contenedor
    alertContainer.appendChild(svgContainer);

    backgroundContainer.appendChild(alertContainer);
    document.body.appendChild(backgroundContainer);

    // Limpiar el almacenamiento local
    localStorage.clear();

    // Redirigir a la página de inicio de sesión después de un tiempo (por ejemplo, 2 segundos)
    setTimeout(() => {
      const loginUrl = "/session/login"; // Cambia esto por la URL de tu página de inicio de sesión
      window.location.href = loginUrl;
    }, 3000); // 2000 milisegundos = 2 segundos
  }
}
