import { defineStore } from "pinia";
import router from "../router";
import axios from "axios";

export const useShowcaseStore = defineStore("showcase", {
  state: () => ({
    data: null,
    products: null,
    product: null,
    local_galery: null,
    uuids: [],
    curr_product: null,
    loadingStepOne: true,
    alreadyLoadedStepOne: false,
  }),

  getters: {},

  actions: {
    clear_all() {
      this.data = null;
      this.products = null;
      this.product = null;
      this.local_galery = null;
      this.uuids = [];
      this.curr_product = null;
    },
  },

  persist: true,
});
