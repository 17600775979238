import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/all";
import MapCurtainLift from "@/utils/MapCurtainLift";
import DashboardEntrance from "./DashboardEntrance";
gsap.registerPlugin(ScrollToPlugin);
export default function zoomedMapExitAnimation() {
  const defaultDuration = 1.5;
  const DashboardEntrancePausedTimeline = DashboardEntrance();

  const addColumnTween = (
    timeline,
    columnId,
    duration = defaultDuration,
    whenStart = "<"
  ) => {
    timeline.to(
      columnId,
      {
        marginTop: "100vh",
        duration: duration,
        ease: "Power2.easeInOut",
      },
      whenStart
    );
  };

  let tl = gsap.timeline({
    onStart: () => {
      gsap.to("#dashboard", {
        duration: 0,
        opacity: 1,
      });
      gsap.from("#dashboard", {
        duration: 1,
        ease: "power2.inOut",
        delay: 0.5,
        marginTop: "-100vh",
      });
    },
    onComplete: () => {
      DashboardEntrancePausedTimeline.play();
    },
  });

  /* 0th grupo 1 2 3 22 21*/
  addColumnTween(tl, "#zoomed-map-column-1", defaultDuration + 0.1, "");
  addColumnTween(tl, "#zoomed-map-column-2", defaultDuration - 0.05);
  addColumnTween(tl, "#zoomed-map-column-3", defaultDuration - 0.07);
  addColumnTween(tl, "#zoomed-map-column-22");
  addColumnTween(tl, "#zoomed-map-column-21");

  /* Primer grupo */
  addColumnTween(tl, "#zoomed-map-column-20", defaultDuration + 0.02);
  addColumnTween(tl, "#zoomed-map-column-23", defaultDuration + 0.03);

  /* segundo grupo 19 18 17 */
  addColumnTween(tl, "#zoomed-map-column-19", defaultDuration, "<+0.03");
  addColumnTween(tl, "#zoomed-map-column-18", defaultDuration + 0.04);
  addColumnTween(tl, "#zoomed-map-column-17", defaultDuration + 0.01);

  /* tercer grupo 16 15 */
  addColumnTween(
    tl,
    "#zoomed-map-column-16",
    defaultDuration + 0.12,
    "<+0.025"
  );
  addColumnTween(tl, "#zoomed-map-column-15", defaultDuration + 0.14);

  /* cuarto grupo 12 13 14 24 25*/
  addColumnTween(tl, "#zoomed-map-column-12", defaultDuration - 0.08, "<+0.02");
  addColumnTween(tl, "#zoomed-map-column-13", defaultDuration - 0.06);
  addColumnTween(tl, "#zoomed-map-column-14", defaultDuration - 0.05);
  addColumnTween(tl, "#zoomed-map-column-24");
  addColumnTween(tl, "#zoomed-map-column-25");

  /* 5to grupo 11 solito */
  addColumnTween(tl, "#zoomed-map-column-11", defaultDuration, "<+0.05");

  /* 6to grupo 10 9 8 7 */
  addColumnTween(tl, "#zoomed-map-column-10", defaultDuration, "<+0.05");
  addColumnTween(tl, "#zoomed-map-column-9");
  addColumnTween(tl, "#zoomed-map-column-8");
  addColumnTween(tl, "#zoomed-map-column-7");

  /* 7mo grupo 6 5 4  */
  addColumnTween(tl, "#zoomed-map-column-6", defaultDuration - 0.17, "<+0.12");
  addColumnTween(tl, "#zoomed-map-column-5", defaultDuration - 0.17);
  addColumnTween(tl, "#zoomed-map-column-4", defaultDuration - 0.17);
  addColumnTween(tl, "#zoomed-map-column-29", defaultDuration - 0.17);
  addColumnTween(tl, "#zoomed-map-column-28", defaultDuration - 0.17);
  addColumnTween(tl, "#zoomed-map-column-27", defaultDuration - 0.17);
  addColumnTween(tl, "#zoomed-map-column-26", defaultDuration - 0.17);

  /* el resto, hasta 29 */

  /* END */
  tl.to(
    ".map-entry",
    {
      display: "none",
    },
    ">-0.85"
  );
  tl.call(MapCurtainLift, null, ">");
}
