<template>
  <Head>
    <title>Curarrehue</title>
  </Head>

  <MapEntry></MapEntry>
  <router-view />

  <rdx-loader v-if="$store.global.loader"></rdx-loader>

  <rdx-confirm v-if="$store.global.confirm.show"></rdx-confirm>
</template>

<script>
import { Head } from "@vueuse/head";
import MapEntry from "./components/MapEntry.vue";

export default {
  data() {
    return {};
  },
  components: {
    Head: Head,
    MapEntry,
  },
  created() {
    this.$store.global.clear_confirm();
  },
  beforeUnmount() {},
  mounted() {},
  methods: {},
};
</script>
