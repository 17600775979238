<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4 8.5H5.75M5.75 8.5C6.21413 8.5 6.65925 8.68437 6.98744 9.01256C7.31563 9.34075 7.5 9.78587 7.5 10.25C7.5 10.7141 7.31563 11.1592 6.98744 11.4874C6.65925 11.8156 6.21413 12 5.75 12H3M5.75 8.5C6.21413 8.5 6.65925 8.31563 6.98744 7.98744C7.31563 7.65925 7.5 7.21413 7.5 6.75C7.5 6.28587 7.31563 5.84075 6.98744 5.51256C6.65925 5.18437 6.21413 5 5.75 5H3M21 15C21 18.314 16.97 21 12 21C7.03 21 3 18.314 3 15M14 5H13C12.2044 5 11.4413 5.31607 10.8787 5.87868C10.3161 6.44129 10 7.20435 10 8V10M10 10C10 10.5304 10.2107 11.0391 10.5858 11.4142C10.9609 11.7893 11.4696 12 12 12H12.5C13.0304 12 13.5391 11.7893 13.9142 11.4142C14.2893 11.0391 14.5 10.5304 14.5 10V9.5C14.5 8.96957 14.2893 8.46086 13.9142 8.08579C13.5391 7.71071 13.0304 7.5 12.5 7.5H12C11.4696 7.5 10.9609 7.71071 10.5858 8.08579C10.2107 8.46086 10 8.96957 10 9.5V10ZM17 8.5V7C17 6.46957 17.2107 5.96086 17.5858 5.58579C17.9609 5.21071 18.4696 5 19 5H19.5C20.0304 5 20.5391 5.21071 20.9142 5.58579C21.2893 5.96086 21.5 6.46957 21.5 7V10C21.5 10.5304 21.2893 11.0391 20.9142 11.4142C20.5391 11.7893 20.0304 12 19.5 12H19C18.4696 12 17.9609 11.7893 17.5858 11.4142C17.2107 11.0391 17 10.5304 17 10V8.5Z"
            stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round" />
    </svg>
</template>

<script>
export default {
    name: "icon-dashboard-360",
};
</script>
