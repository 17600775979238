import { useSessionStore } from "../stores/session.store.js";
import { useGlobalStore } from "../stores/global.store.js";
import { util } from "./util.js";
import axios from "axios";
import MessageAlert from "@/views/startup/components/MessageAlert.vue";

// axios.interceptors.request.use((config) => {
//   config.headers["Access-Control-Allow-Origin"] = "*";
//   config.headers["Access-Control-Allow-Methods"] = "PATCH, GET, POST, PUT, DELETE, OPTIONS";
//   config.headers["Access-Control-Allow-Headers"] = "Content-Type, Authorization",'accept','accept-encoding','accept-language','cache-control','content-length','content-type','dnt','origin','user-agent','x-csrftoken','x-requested-with';

//   return config;
// });

export const useApi = function (vueApp, handleTokenError) {
  const sessionStore = useSessionStore();
  const globalStore = useGlobalStore();
  // const endpoint = "https://curarrehue.rdx.social/";
  const endpoint = "https://local-attract.api.rdx.center/";
  const activeRequests = [];

  function addRequest(config) {
    activeRequests.push(config);
  }
  function removeRequest(config) {
    const index = activeRequests.findIndex((req) => req === config);
    if (index > -1) {
      activeRequests.splice(index, 1);
    }
  }

  const api = {
    post: async function (args) {
      globalStore.loader = true;
      await axios
        .post(this.createUrl(args.url), args.data, {
          headers: { Authorization: `Bearer ${sessionStore.token}` },
        })
        .then((resp) => {
          args.success(resp);
          globalStore.loader = false;
        })
        .catch((err) => {
          if (
            err.response.data.error === "token expirado o inválido" ||
            err.response.data.detail ===
              "Authentication credentials were not provided."
          ) {
            handleTokenError(err);
          } else {
            try {
              if ("error" in err.response.data) {
                args.error(err.response.data);
              } else {
                args.error(null);
              }
            } catch (e) {
              args.error(null);
            }
            globalStore.loader = false;
          }
        });
    },

    delete: async function (args) {
      globalStore.loader = true;
      await axios
        .delete(this.createUrl(args.url), {
          headers: { Authorization: `Bearer ${sessionStore.token}` },
        })
        .then((resp) => {
          //resp = resp.map((r)=> { return r.data});
          args.success(resp);
          globalStore.loader = false;
        })
        .catch((err) => {
          if (
            err.response.data.error === "token expirado o inválido" ||
            err.response.data.detail ===
              "Authentication credentials were not provided."
          ) {
            handleTokenError(err);
          } else {
            args.error(err);
            globalStore.loader = false;
          }
        });
    },

    patch: async function (args) {
      globalStore.loader = true;
      await axios
        .patch(this.createUrl(args.url), args.data, {
          headers: { Authorization: `Bearer ${sessionStore.token}` },
        })
        .then((resp) => {
          //resp = resp.map((r)=> { return r.data});
          args.success(resp);
          globalStore.loader = false;
        })
        .catch((err) => {
          if (
            err.response.data.error === "token expirado o inválido" ||
            err.response.data.detail ===
              "Authentication credentials were not provided."
          ) {
            handleTokenError(err);
          } else {
            try {
              if ("error" in err.response.data) {
                args.error(err.response.data);
              } else {
                args.error(null);
              }
            } catch (e) {
              args.error(null);
            }
            globalStore.loader = false;
          }
        });
    },

    get: async function (args) {
      globalStore.loader = true;
      let promises = [];
      for (let i = 0; i < args.url.length; i++) {
        const url = this.createUrl(args.url[i][0]);
        const params = args.url[i][1] || {};
        if ("_filter_" in params) {
          params._filter_ = JSON.stringify(params._filter_);
        }
        promises.push(
          axios.get(url, {
            params,
            headers: { Authorization: `Bearer ${sessionStore.token}` },
          })
        );
      }

      //$q.loading.show();

      //$q.loading.show();
      await axios
        .all(promises)
        .then(
          axios.spread((...resp) => {
            //resp= resp.map((r)=> { return r.data});
            args.success(...resp);
            globalStore.loader = false;
          })
        )
        .catch((err) => {
          try {
            if (
              err.response.data.error === "token expirado o inválido" ||
              err.response.data.detail ===
                "Authentication credentials were not provided."
            ) {
              handleTokenError(err);
            } else {
              //this.state.ts.loader.show = false;
              ////console.log(err);
              //if ('error' in args) {
              args.error(err);
              globalStore.loader = false;
              //}
              //$q.loading.hide();
            }
          } catch (e) {
            args.error(null);
            globalStore.loader = false;
          }
        });
    },

    getApiEndpoint: function () {
      return endpoint;
    },
    createUrl: function (url) {
      let apiURL = url;
      console.log(apiURL);
      console.log(typeof apiURL);

      if (typeof apiURL === "string" && !apiURL.startsWith("http")) {
        apiURL = util.joinEndpointAndUrl(endpoint, url);
      }
      return apiURL;
    },
  };
  axios.interceptors.request.use(
    function (config) {
      // Agregar la solicitud a la lista de solicitudes en curso
      addRequest(config);
      //console.log(config);
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    function (response) {
      // Eliminar la solicitud de la lista de solicitudes en curso
      removeRequest(response.config);
      //console.log(response);
      return response;
    },
    function (error) {
      // Eliminar la solicitud de la lista de solicitudes en curso
      removeRequest(error.config);
      return Promise.reject(error);
    }
  );
  api.getActiveRequests = function () {
    return activeRequests;
  };

  return api;
};
