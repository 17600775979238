<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 10V19V21H21V19V10" stroke="currentColor" stroke-width="1.5" />
        <path d="M14.833 21V15V13H8.83301V15V21" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="16" />
        <path
            d="M21.818 9.364L20.124 3.435C20.0881 3.30965 20.0124 3.19939 19.9083 3.1209C19.8042 3.04241 19.6774 2.99997 19.547 3H15.5L15.975 8.704C15.9822 8.79572 16.0112 8.8844 16.0595 8.96267C16.1079 9.04093 16.1742 9.10653 16.253 9.154C16.643 9.387 17.405 9.817 18 10C19.016 10.313 20.5 10.2 21.346 10.096C21.4282 10.0854 21.5072 10.0569 21.5773 10.0126C21.6474 9.96835 21.707 9.90929 21.752 9.8396C21.7969 9.7699 21.8261 9.69123 21.8375 9.60909C21.8489 9.52695 21.8423 9.44331 21.818 9.364Z"
            stroke="currentColor" stroke-width="1.5" />
        <path
            d="M14 10C14.568 9.825 15.288 9.426 15.69 9.188C15.7838 9.13222 15.8598 9.05108 15.9093 8.95393C15.9589 8.85678 15.9799 8.7476 15.97 8.639L15.5 3H8.50005L8.03005 8.639C8.01998 8.74777 8.04094 8.85716 8.0905 8.9545C8.14005 9.05184 8.21618 9.13315 8.31005 9.189C8.71205 9.426 9.43205 9.825 10 10C11.493 10.46 12.507 10.46 14 10Z"
            stroke="currentColor" stroke-width="1.5" />
        <path
            d="M3.87605 3.435L2.18205 9.365C2.15814 9.44418 2.15175 9.52762 2.16332 9.60951C2.17489 9.69141 2.20414 9.76981 2.24905 9.83927C2.29396 9.90873 2.35345 9.96758 2.42339 10.0117C2.49332 10.0559 2.57203 10.0843 2.65405 10.095C3.49905 10.2 4.98405 10.312 6.00005 10C6.59505 9.817 7.35805 9.387 7.74705 9.155C7.82599 9.10743 7.89241 9.04168 7.94077 8.96323C7.98913 8.88477 8.01803 8.79589 8.02505 8.704L8.50005 3H4.45305C4.32267 2.99997 4.19583 3.04241 4.09172 3.1209C3.98762 3.19939 3.91191 3.30965 3.87605 3.435Z"
            stroke="currentColor" stroke-width="1.5" />
    </svg>
</template>

<script>
export default {
    name: "icon-dashboard-shop",
};
</script>
