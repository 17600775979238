<template>
<div :id="id" class="btn btn-primary" :style="{ width: width }" :class="{ 'disabled': isUploading || isUploadDisabled }">
    <slot></slot>

    <span v-if="show_plus" class="r-plus"> +</span>
  </div>
</template>

<style lang="scss" scoped>
.btn-primary {
  background-color: #ea3724 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color:white !important;
  box-shadow: none;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>

<script>
export default {
  name: "rdx-button",
  props: {
    width: { default: null },
  },
  data() {
  return {
    id: Math.random(),
    show_plus: false,
    isUploadDisabled: false,
    uploading_cover: false,
    uploading_gallery: false,
  };
},

  mounted() {
    var btn = document.getElementById(this.id);
    if (btn.className.search("r-plus") >= 0) {
      this.show_plus = true;
    }
    // Si no agregue ninguna clase, fuerzo a que sea r-bg-red
    if (btn.className == "btn") {
      btn.className = "btn btn-primarry";
    }

    // Escucha el evento personalizado "uploadStatusChanged" emitido por el tercer código
    document.addEventListener("uploadStatusChanged", this.updateUploadStatus);
  },
  beforeUnmount() {
    // Elimina el evento personalizado antes de desmontar el componente
    document.removeEventListener("uploadStatusChanged", this.updateUploadStatus);
  },
  methods: {
  updateUploadStatus(event) {
    const { uploading_cover, uploading_gallery } = event.detail;
    this.uploading_cover = uploading_cover;
    this.uploading_gallery = uploading_gallery;

    // Verificar ambas condiciones antes de deshabilitar el botón
    this.isUploadDisabled = uploading_cover || uploading_gallery;
  },
},

};
</script>