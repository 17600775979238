<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 11V3H6C4.89543 3 4 3.89543 4 5V18H11" stroke="currentColor" stroke-width="1.5" />
        <path d="M4 18V19C4 20.1046 4.89543 21 6 21H12" stroke="currentColor" stroke-width="1.5" />
        <path
            d="M15.898 14.0792C15.8068 14.0271 15.7035 13.9997 15.5985 14C15.4934 14.0003 15.3903 14.0281 15.2993 14.0808C15.2084 14.1334 15.1329 14.209 15.0804 14.3C15.0279 14.391 15.0001 14.4942 15 14.5992V20.5322C15 20.6374 15.0275 20.7407 15.08 20.8318C15.1325 20.9229 15.208 20.9987 15.2989 21.0514C15.3898 21.1042 15.4931 21.1322 15.5982 21.1325C15.7034 21.1328 15.8067 21.1055 15.898 21.0532L21.088 18.0872C21.1799 18.0348 21.2562 17.9589 21.3093 17.8674C21.3624 17.7759 21.3904 17.672 21.3904 17.5662C21.3904 17.4604 21.3624 17.3565 21.3093 17.265C21.2562 17.1735 21.1799 17.0977 21.088 17.0452L15.898 14.0792Z"
            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
        <rect x="7" y="6" width="8" height="3" fill="currentColor" />
    </svg>
</template>

<script>
export default {
    name: "icon-dashboard-trainings",
};
</script>
