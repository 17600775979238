<template>
        <svg width="36" height="36" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="7.75" y="12.75" width="28.5" height="19.5" stroke="white" stroke-width="1.5"/>
        <circle cx="35.4" cy="30.7999" r="7.65" fill="black" stroke="white" stroke-width="1.5"/>
        <path d="M35.0176 27.7454V31.9454" stroke="white"/>
        <path d="M37.6914 31.5635H34.6369" stroke="white"/>
        </svg>
</template>

<script>
export default {
    name: "icon-dashboard-approved",
};
</script>


    