import { createWebHistory, createRouter } from "vue-router";
//import Login from "@/views/session/Login.vue";
//import About from "@/views/About.vue";
//import Dashboard from "@/views/dashboard/Layout.vue";

/**
 *
 * '...Container' components are used to wrap the children components
 * 'Index' components are used to display the list of children components, for example, the list of trainings or the list of products
 * 'Show' components are used to display the details of a single item, for example, the details of a single training or the details of a single product
 * 'Edit' components are used to edit the details of a single item, for example, the details of a single training or the details of a single product
 * 'Form' components are used to create a new item, for example, the form to create a new training or the form to create a new product
 */
const routes = [
  /*
  {
    path: "/login",
    component: () => import('@/views/session/Index.vue'),
  },
  */
  /*
 {
   path: "/register",
   component: () => import('@/views/session/Register.vue'),
 },
 
 {
   path: "/verification",
   component: () => import('@/views/session/Verification.vue'),
 },  
 */
  /* this.$store.session.isLogged */
  // {
  //   path: "/home",
  //   component: () => import("@/views/landing/HomeVisitante.vue"),
  // },
  // {
  //   path: "/emprendedores",
  //   component: () => import("@/views/landing/HomeEmprendedor.vue"),
  // },
  // {
  //   path: "/",
  //   component: () => import("@/views/landing/HomeVisitante.vue"),
  //   redirect: "/dashboard",
  // },

  // {path:"/dashboard",

  // redirect: "/dashboard",
  // },

  {
    path: "/session",
    component: () => import("@/views/session/SessionContainer.vue"),
    children: [
      {
        path: "/session/login",
        component: () => import("@/views/session/Login.vue"),
      },
      {
        path: "/session/register",
        component: () => import("@/views/session/Register.vue"),
      },
      {
        path: "/session/verification",
        component: () => import("@/views/session/Verification.vue"),
      },
      {
        path: "/session/recover",
        component: () => import("@/views/session/Recover.vue"),
      },
      {
        path: "/session/reset",
        component: () => import("@/views/session/Reset.vue"),
      },
    ],
  },

  {
    path: "/startup",
    component: () => import("@/views/startup/StartupContainer.vue"),
    redirect: "/startup/step1",
    children: [
      {
        path: "/startup/step1",
        component: () => import("@/views/startup/Step1_Interests.vue"),
      },
      {
        path: "/startup/step2",
        component: () => import("@/views/startup/Step2_Business.vue"),
      },
      {
        path: "/startup/step3",
        component: () => import("@/views/startup/Step3_Contact.vue"),
      },
      {
        path: "/startup/step4",
        component: () => import("@/views/startup/Step4_Map.vue"),
      },
      {
        path: "/startup/step5",
        component: () => import("@/views/startup/Step5_Products.vue"),
      },
      {
        path: "/startup/step6",
        component: () => import("@/views/startup/Step6_Welcome.vue"),
      },
    ],
  },

  {
    path: "/",
    redirect: "/vitrina/",
    component: () => import("@/views/dashboard/DashboardContainer.vue"),
    children: [
      {
        path: "/vitrina",
        component: () =>
          import("@/views/dashboard/vitrina/VitrinaContainer.vue"),
        children: [
          {
            path: "/vitrina/",
            component: () =>
              import("@/views/dashboard/vitrina/business/Show.vue"),
          },
          {
            path: "/vitrina/edit",
            component: () =>
              import("@/views/dashboard/vitrina/business/Edit.vue"),
          },
          {
            path: "/vitrina/contact",
            component: () =>
              import("@/views/dashboard/vitrina/contact/Show.vue"),
          },
          {
            path: "/vitrina/contact/edit",
            component: () =>
              import("@/views/dashboard/vitrina/contact/Edit.vue"),
          },
          {
            path: "/vitrina/location",
            component: () =>
              import("@/views/dashboard/vitrina/location/Show.vue"),
          },
          {
            path: "/vitrina/location/edit",
            component: () =>
              import("@/views/dashboard/vitrina/location/Edit.vue"),
          },
        ],
      },
      {
        path: "/products",
        component: () => import("@/views/dashboard/product/Index.vue"),
      },
      {
        path: "/product",
        component: () => import("@/views/dashboard/product/Show.vue"),
      },
      {
        path: "/product/edit",
        component: () => import("@/views/dashboard/product/Edit.vue"),
      },
      {
        path: "/trainings",
        component: () => import("@/views/dashboard/training/Index.vue"),
      },
      {
        path: "/resources",
        component: () =>
          import("@/views/dashboard/training/resources/Index.vue"),
      },
      {
        path: "/resources/new",
        component: () => import("@/views/dashboard/training/resources/New.vue"),
      },
      {
        path: "/resources/edit",
        component: () =>
          import("@/views/dashboard/training/resources/Edit.vue"),
      },
      {
        path: "/notificaciones",
        component: () => import("@/views/dashboard/notificaciones/Index.vue"),
      },
      {
        path: "/notificaciones/new",
        component: () => import("@/views/dashboard/notificaciones/New.vue"),
      },
      {
        path: "/notificaciones/edit",
        component: () => import("@/views/dashboard/notificaciones/Edit.vue"),
      },

      {
        path: "/startups",
        component: () => import("@/views/dashboard/startups/Index.vue"),
      },
      {
        path: "/startups/content",
        component: () => import("@/views/dashboard/startups/content/Show.vue"),
      },
      {
        path: "/startups/content/edit",
        component: () => import("@/views/dashboard/startups/content/Edit.vue"),
      },

      {
        path: "/current_user",
        component: () => import("@/views/dashboard/currentuser/Index.vue"),
      },

      {
        path: "/entrepreneurs/gallery/",
        component: () =>
          import("@/views/dashboard/entrepreneurs/gallery/VitrinaList.vue"),
      },
      {
        path: "/entrepreneurs/evaluation",
        component: () =>
          import("@/views/dashboard/entrepreneurs/EntrepreneursContainer.vue"),
        redirect: "/entrepreneurs/evaluation/vitrina",
        children: [
          {
            path: "/entrepreneurs/evaluation/vitrina",
            component: () =>
              import("@/views/dashboard/entrepreneurs/business/Show.vue"),
          },
          {
            path: "/entrepreneurs/evaluation/contact/",
            component: () =>
              import("@/views/dashboard/entrepreneurs/contact/Show.vue"),
          },
          {
            path: "/entrepreneurs/evaluation/location/",
            component: () =>
              import("@/views/dashboard/entrepreneurs/location/Show.vue"),
          },
        ],
      },
    ],
  },

  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("@/components/RDxPageNotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: "/",
  routes,
});

export default router;
