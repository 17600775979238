<script setup>
import { onMounted } from "vue";
import zoomedMapEnterAnimation from "@/utils/zoomedMapEnterAnimation.js";
import IconLogoIsotypeLong from "./IconLogoIsotypeLong.vue";

onMounted(() => {
  /*     zoomedMapEnterAnimation();
   */
});

const numColumns = 29;
</script>

<template>
  <div class="w-100 map-entry">
    <div
      v-for="i in numColumns"
      class="zoomed-map-column"
      :id="'zoomed-map-column-' + i"
      v-bind:key="i"
    ></div>
    <!--     <IconLogoIsotypeLong
      class="position-absolute araucaria-central moving-tree"
      style="width: min(20%, 10em); height: auto; top: 90%"
    /> -->
  </div>
</template>

<style scoped lang="scss">
#zoomed-map-column-1 {
  margin-left: -1vw;
}

.zoomed-map-column {
  width: 2.06235vw !important;
  height: 105vh;
  margin-left: 1.62vw;
  background-color: rgb(0, 0, 0);
}

.map-entry {
  background-color: transparent;
  z-index: 99999500;
  overflow: hidden;
  display: flex !important;
  opacity: 0;
  position: absolute;
  height: 100vh;
  pointer-events: none;
}

.araucaria-central {
  left: 50%;
  bottom: 0px;
  transform: translate3d(-50%, 0, 0);
  height: min(70vw, 40vh);
  position: absolute;
  position: absolute;
  height: 55vh;
  width: auto;
}

/* custom heights */

#zoomed-map-column-1 {
  height: 23vh;
  margin-top: 30vh;
}

#zoomed-map-column-2 {
  height: 39vh;
  margin-top: 30vh;
}

#zoomed-map-column-3 {
  height: 70vh;
  margin-top: 15vh;
}

#zoomed-map-column-4 {
  margin-top: 15vh;
}

#zoomed-map-column-15 {
  height: 80vh;
}

#zoomed-map-column-16,
#zoomed-map-column-17,
#zoomed-map-column-18,
#zoomed-map-column-19,
#zoomed-map-column-20,
#zoomed-map-column-21,
#zoomed-map-column-22 {
  height: 90vh;
}

#zoomed-map-column-23 {
  height: 80vh;
  margin-top: 10vh;
}

#zoomed-map-column-24 {
  margin-top: 10vh;
  height: 73vh;
}

#zoomed-map-column-25 {
  height: 83vh;
}

#zoomed-map-column-26 {
  height: 76vh;
}
#zoomed-map-column-27 {
  height: 70vh;
}
#zoomed-map-column-28 {
  height: 63vh;
}

#zoomed-map-column-29 {
  height: 56vh;
}
</style>
