<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.9998 16.7061C10.9857 15.4343 10.3909 14.32 10.1911 13.3644C9.99469 12.5934 10.0707 11.9778 10.4111 11.5152C10.7685 10.9817 11.2992 10.7239 11.9998 10.7239C12.7005 10.7239 13.234 10.9817 13.5913 11.5214C13.9256 11.9789 14.0106 12.594 13.8052 13.3706C13.5851 14.3436 12.9903 15.4573 11.9964 16.7123L11.9998 16.7061ZM19.2015 17.5621C19.0636 18.4968 18.4277 19.2706 17.5515 19.6488C15.8632 20.3843 14.1895 19.2121 12.759 17.6206C15.1243 14.6571 15.5638 12.3503 14.5469 10.8584C13.952 10.0025 13.0961 9.58659 11.9998 9.58659C9.79266 9.58659 8.57934 11.4538 9.05713 13.6227C9.33288 14.7983 10.0712 16.1315 11.2435 17.6234C10.5079 18.4355 9.81011 19.0157 9.19444 19.373C8.71666 19.6308 8.25969 19.7895 7.82242 19.8272C5.81448 20.126 4.23762 18.18 4.95289 16.1692C5.0525 15.9081 5.25172 15.4337 5.58882 14.6976L5.60626 14.6565C6.70534 12.2738 8.03909 9.5652 9.56924 6.56117L9.61032 6.46156L10.0442 5.62642C10.3841 5.00794 10.522 4.73219 11.0583 4.39228C11.3194 4.23695 11.6357 4.1576 11.9931 4.1576C12.7083 4.1576 13.2649 4.57405 13.5058 4.91115C13.6228 5.0901 13.7635 5.3304 13.9425 5.62642L14.3617 6.44411L14.4203 6.56117C15.9504 9.56576 17.287 12.2682 18.3799 14.6565L18.3973 14.6768L18.7991 15.5913L19.0366 16.1659C19.2184 16.6234 19.2595 17.0803 19.1976 17.5581L19.2015 17.5621ZM20.116 15.7714C19.9781 15.3318 19.7378 14.8158 19.4418 14.2001V14.1765C18.0259 11.1719 16.7102 8.4695 15.4614 6.04286L15.3792 5.92243C14.4884 4.09626 13.8519 3 11.9998 3C10.1708 3 9.39422 4.27185 8.5996 5.92525L8.53826 6.04568C7.28667 8.4695 5.97375 11.1753 4.56065 14.1793V14.2204L4.14139 15.1349C3.98606 15.5131 3.9039 15.7095 3.88364 15.7708C2.86954 18.5554 4.95908 21 7.48589 21C7.50615 21 7.5855 21 7.6823 20.9826H7.96368C9.27661 20.8216 10.6278 19.9859 11.9998 18.494C13.3718 19.9859 14.7287 20.821 16.0388 20.9826H16.3173C16.417 21 16.4963 21 16.5166 21C19.0434 21.0028 21.1301 18.5559 20.116 15.7714Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.4"
        />
    </svg>
</template>

<script>
export default {
    name: "icon-social-airbnb",
};
</script>
