<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15Z"
            stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M19.622 10.395L18.525 7.745L20 6L18 4L16.265 5.483L13.558 4.37L12.935 2H10.981L10.349 4.401L7.704 5.516L6 4L4 6L5.453 7.789L4.373 10.446L2 11V13L4.401 13.655L5.516 16.3L4 18L6 20L7.791 18.54L10.397 19.612L11 22H13L13.604 19.613L16.255 18.515C16.697 18.831 18 20 18 20L20 18L18.516 16.25L19.614 13.598L22 12.978V11L19.622 10.395Z"
            stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>

<script>
export default {
    name: "icon-dashboard-settings",
};
</script>
