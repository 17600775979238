<script>
export default {
  name: "rdx-checkbox",
  props: {
    text: {
      type: String,
      required: true,
    },
    value: { default: null },
  },
  data() {
    return {
      modelValue: this.value,
      isCreated: false,
    };
  },
  mounted() {
    this.isCreated = true;
  },
  computed: {
    animationDuration() {
      return `${0.75*Math.random()}s`;
    },
  },
  watch: {
    modelValue: {
      handler(newValue, oldValue) {
        this.$emit("update:value", newValue);
      },
      deep: true,
    },
  },
};
</script>

<template>
  <li
    class="tg-list list-group-item"
    :style="{
      animationDuration: animationDuration,
    }"
  >
    <div class="col-8">
      <h5 class="mb-2 interest-tag">{{ text }}</h5>
    </div>
    <div class="form-check form-switch col-4 center justify-content-end">
      <input class="form-check-input" type="checkbox" v-model="modelValue" />
    </div>
  </li>
</template>

<style lang="scss" scoped>
.form-switch .form-check-input:checked {
  background-color: #EA3724;
  border-color: transparent;
}

.form-check-input {
  width: 50px;
  border-radius: 60px;
  background-color: #eeee;
  justify-content: center;
  height: 25px;
  &::after {
    width: 19px;
    height: 19px;
    margin-left: 2px;
    margin-top: 1px;
    border-radius: 50%;
    transform: translateX(0px) !important;
  }
}
.form-switch .form-check-input:checked:after {
  transform: translateX(130%) !important;
}

.interest-tag {
  width: 100% !important;
  color: #646464 !important;
  margin-top: 12px;
  margin-right: 15px !important;
  font-family: "roboto" !important;
}

.list-group-item {
  margin-bottom: 12px !important;
  border: none !important;
  width: 100% !important;
  padding: 1px !important;
}

.tg-list.list-group-item {
  animation: fade-in cubic-bezier(.99,.07,.83,.67);
}


@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
