<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 10C19 15 12 21 12 21C12 21 5 15 5 10C5 6.13401 8.13401 3 12 3C15.866 3 19 6.13401 19 10Z"
            stroke="currentColor" stroke-width="1.5" />
        <circle cx="12" cy="10" r="2" fill="currentColor" />
    </svg>
</template>

<script>
export default {
    name: "icon-dashboard-location",
};
</script>
